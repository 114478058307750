























































































































































































































































export default {
  name: 'PCSelectCard',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
