








import { ref } from '@vue/composition-api';
import { useUserGetters } from '@/store';
import axios from 'axios';
import PCSelectCard from './PCSelectCard.vue';

export default {
  name: 'Select',

  components: {
    'pc-select-card': PCSelectCard
  },

  setup(_props, ctx) {
    const { getObjectId } = useUserGetters(['getObjectId']);
    const creating = ref(false);

    async function createProgram() {
      try {
        creating.value = true;
        const data = {
          organizers: [{ type: 'user', userId: getObjectId.value }],
          participants: [],
          dateCreated: new Date(),
          licensed: false,
          createdBy: getObjectId.value
        };

        await axios
          .post(`${process.env.VUE_APP_PROGRAM_DOC}/program`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          })
          .then(res => {
            ctx.root.$router.push({
              name: 'guide',
              params: { programId: res.data._id, page: '0' }
            });
            creating.value = false;
          });
      } catch (err) {
        console.log(err);
        creating.value = false;
      }
    }

    return { createProgram, creating };
  }
};
