












import { defineComponent, reactive, toRefs } from '@vue/composition-api';

export default defineComponent({
  name: 'AIProgramDesigner',

  components: {},

  setup() {
    const stepperData = reactive({
      currentStep: 1
    });

    return {
      ...toRefs(stepperData)
    };
  }
});
